import React from "react"
import Flex from "../../../utils/flex"
import { DesktopBreakpoint } from "../../../breakpoints"
import CurrencyTight from "../../../animations/currency-tight"

const CopytradePlatformIntro = () => {
  return (
    <>
      <Flex
        alignTop
        className="md:pl-[29px] md:!pt-[80px] md-max:pb-[50px] md-max:pt-[40px]"
        firstItem={
          <>
            <h1 className="font-extrabold md:text-[40px] md:leading-[1.25]">Copytrade</h1>
            <p className='md-max:max-w-[340px]'>
              Sometimes, trading on your own can be quite difficult &
              <DesktopBreakpoint />
              {" "}that’s why we’ve partnered with a global brand to bring
              <DesktopBreakpoint />
              {" "}you Egmarkets’ copytrade.
            </p>

            <p className="md-max:mt-0 md-max:max-w-[340px] md-max:mb-[14px]" >
              Now you can follow pro traders across the globe &{' '}
              <DesktopBreakpoint />
              make money in the financial markets when they do <DesktopBreakpoint /> without knowing how to {' '}
              trade for yourself.
            </p>
          </>
        }
        secondItem={
          <div className='lottie-animation md:mt-[-48px] md:w-[500px] md-max:max-w-[360px] md-max:w-full max-w-[100%]'>
            <CurrencyTight/>
          </div>
        }
        secondItemClassName='lg-max:mt-0'
      />
    </>
  )
}

export default CopytradePlatformIntro
