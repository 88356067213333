import React from "react"
import { Button, Container, Flex } from "../../../utils"

import { StaticImage } from "gatsby-plugin-image"

import ctl from "@netlify/classnames-template-literals"
import leftBarrelShape from "icons/opportunities/shape-left.svg"
import rightBarrelShape from "icons/opportunities/shape-right-bold.svg"
import leftBarrelShapeM from "icons/opportunities/shape-left-m.svg"
import rightBarrelShapeM from "icons/opportunities/shape-right-m.svg"
import { DesktopBreakpoint, MobileBreakpoint } from "../../../breakpoints"

const CopytradeTradersEcosystem = () => {
  const barrelLeftStyle = ctl(
    `shape block  
    1.5xl-max:w-[294px] md-max:bottom-0 
    md:translate-x-[-65%]
    translate-x-[-274px]
    left-0
    md:top-[50px]`
  )

  const barrelRightStyle = ctl(`
  shape block 
  1.5xl-max:w-[294px]
  right-0 md:translate-x-[65%] translate-x-[274px]
  top-[80px] md:top-[-40px] 1.5xl:top-[-70px]
  `)

  return (
    <Container size="medium" className="md-max:px-[40px]">
      <Flex
        reverseMobile
        justifyCenter
        className="gap-x-5 md:pt-[180px] md-max:pt-[0px]"
        firstItemClassName="md:!min-w-[490px]"
        firstItem={
          <>
            <h2 className="font-extrabold text-[32px] md:text-[40px]">Traders Ecosystem</h2>
            <p className={paragraphStyle}>
              Receive weekly emails featuring best <MobileBreakpoint/> traders <DesktopBreakpoint />
              to follow. Pick a trader from the <MobileBreakpoint/> pack and start <DesktopBreakpoint />
              your copytrading <MobileBreakpoint/> experience.
            </p>
            <Button
              type="primary"
              label="Try Copytrade"
              url="https://eaglegm.zulutrade.com/"
              isExternal
              className={buttonStyle}
            />
          </>
        }
        secondItemClassName="lg-max:mt-0"
        secondItem={
          <>
            <div className={imageStyle}>
              <StaticImage
                src="../../../../assets/images/pages/copytrade/traders-ecosystem.webp"
                alt="Traders ecosystem"
              />
            </div>
            <div className={imageMobileStyle}>
              <StaticImage
                src="../../../../assets/images/pages/copytrade/traders-ecosystem-mobile.webp"
                alt="Traders ecosystem"
              />
            </div>
          </>
        }
      />
      <img src={leftBarrelShape} alt="" className={`${barrelLeftStyle} md-max:hidden`} />
      <img src={rightBarrelShape} alt="" className={`${barrelRightStyle} md-max:hidden`} />
      <img src={leftBarrelShapeM} alt="" className={`${barrelLeftStyle} md:hidden`} />
      <img src={rightBarrelShapeM} alt="" className={`${barrelRightStyle} md:hidden`} />
    </Container>
  )
}

const buttonStyle = ctl(`md:mt-[50px] !mt-[30px] md-max:w-full md-max:flex md-max:items-center md-max:justify-center md-max:h-[50px] md-max:text-sm`)
const imageStyle = ctl(`mx-auto max-w-[448px] md:mb-0 mb-[55px] md-max:hidden`)
const imageMobileStyle = ctl(`mx-auto max-w-[320px] md:mb-0 mb-[55px] md:!hidden`)
const paragraphStyle = ctl(`md:text-inherit text-[17px] leading-[1.6] md:text-[20px] md-max:mt-4 md-max:w-[310px] md-max:mx-auto`)

export default CopytradeTradersEcosystem
