import React from "react"
import ctl from "@netlify/classnames-template-literals"
import sideballLeft from "../../../../../assets/images/pages/copytrade/sideball-left.webp"
import sideballRight from "../../../../../assets/images/pages/copytrade/sideball-right.webp"
import sideballRightMobile from "../../../../../assets/images/pages/copytrade/sideball-right-mobile.svg"

const HowToGetStartedTitle = () => {
  return (
    <div className={divStyle}>
      <img src={sideballLeft} alt="" className='absolute left-[-275px] h-[180px] lg-max:hidden' />
      <img src={sideballRight} alt="" className='absolute top-[-130px] right-[-165px] w-[668px] lg-max:hidden' />
      <img src={sideballRightMobile} alt="" className='absolute top-[0px] right-[-145px] md:hidden' />
      <h2 className='font-extrabold text-[40px]'>
        Here's how <br /> to get started.
      </h2>
      <p className={paragraphStyle}>4 easy steps & you are done</p>
    </div>
  )
}

const divStyle = ctl(`md-max:pt-[160px] text-center z-10 md:mt-[190px]`)
const paragraphStyle = ctl(`text-[17px]`)

export default HowToGetStartedTitle
