import React from "react"
import { steps } from "./data"
import CopytradeSteps from "./steps"
import HowToGetStartedTitle from "./title"
import { Container } from "../../../../utils"
import sideballLeft from "../../../../../assets/images/pages/copytrade/sideball-left-mobile.svg"

const CopytradeHowToGetStarted = () => {
  return (
    <Container className="md:px-[20px] md-max:mb-[260px]" disableXLPadding size="large">
      <HowToGetStartedTitle />
      <CopytradeSteps steps={steps} />
      <img
        src={sideballLeft}
        alt=""
        className='absolute left-[-163px] h-[226px] md:hidden top-[100%] transform translate-y-[60px]'
      />
    </Container>
  )
}

export default CopytradeHowToGetStarted
