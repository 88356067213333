import React from "react"
import OpenAnAccountIcon from "icons/copytrade/open-an-account.svg"
import SignUpForCopytradeIcon from "icons/copytrade/sign-up-for-copytrade.svg"
import SignTheLodFormIcon from "icons/copytrade/sign-the-lod-form.svg"
import FundAndStartCopytradingIcon from "icons/copytrade/fund-and-start-copytrading.svg"

export const steps = [
  {
    icon: OpenAnAccountIcon,
    heading: (
      <>
        Open a Live
        MT4 Account
      </>
    ),
    description: (
      <>
        You’ll need a live MT4 account to <br className='desktop-breakpoint'/>
        connect to your copytrade account
      </>
    ),
    button: {
      label: "Open a Live account",
      url: "https://clients.eagleglobalmarkets.com/#/",
      hasArrow: true,
      type: "tertiary",
      isExternal: true,
    },
    imageClassName: 'w-[61px]'
  },

  {
    icon: SignUpForCopytradeIcon,
    heading: (
      <>
        Sign up for
        Copytrade
      </>
    ),
    description: (
      <>
        Open a copytrade account & <br className='desktop-breakpoint'/>
        connect it to your live account.
      </>
    ),
    button: {
      label: "Open a Copytrade account",
      url: "https://eaglegm.zulutrade.com/",
      hasArrow: true,
      isExternal: true,
      type: "tertiary",
    },
    imageClassName: 'w-[41px]'
  },
  {
    className: 'md:pl-[20px]',
    icon: SignTheLodFormIcon,
    heading: (
      <>
        Sign the
        LOD form
      </>
    ),
    description: (
      <>
        Fill the LOD form and <br className='desktop-breakpoint'/>
        append your signature.
      </>
    ),
    imageClassName: 'w-[59px]'
  },
  {
    icon: FundAndStartCopytradingIcon,
    heading: (
      <>
        Fund & start
        Copy trading
      </>
    ),
    description: (
      <>
        Fund with $100 (minimum) & <br className='desktop-breakpoint'/>
        follow expert traders globally.
      </>
    ),
    imageClassName: 'w-[72px]'
  },
]
