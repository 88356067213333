import React from "react"
import Button from "../../../../utils/button"
import ctl from "@netlify/classnames-template-literals"

const CopytradeSteps = ({ steps }) => {
  return (
    <section className={sectionStyle}>
      {steps.map((step, index) => {
        const { icon, heading, description, button, imageClassName } = step

        return (
          <div key={index} className={stepStyle}>
            <div>
              <img src={icon} alt="" className={`${iconStyle} ${imageClassName}`} />
            </div>
            <h3 className={headingStyle}>{heading}</h3>
            <p className={paragraphStyle}>{description}</p>
            {button && <Button {...button} className='flex items-center md:text-lg md-max:text-xl !py-0 md-max:mt-[30px] !mt-4 md:font-medium'/>}
          </div>
        )
      })}
    </section>
  )
}

const stepStyle = ctl(`
    py-[52px] px-[33px]
    md:py-[30px] md:px-10 
    border-[2px] rounded-[20px] border-button-blue
    md-max:min-h-[327px] md:h-[277px]
    flex flex-col md:justify-center
  
`)

const sectionStyle = ctl(
  `md:mt-[93px] 
  w-[fit-content] mt-[60px] 
  grid md:grid-cols-[345px,345px] grid-cols-1 
  gap-[60px] 
  md:gap-x-[100px] md:gap-y-[127px]
  md-max:px-[10px] 
  md:mx-auto
  `
)

const iconStyle = ctl(`max-w-[105px]`)

const headingStyle = ctl(`mt-8 md:mt-[22px] flex-wrapper md:text-[20px] text-[24px]`)

const paragraphStyle = ctl(`mt-[15px] md:text-[14px] text-[16px]`)

export default CopytradeSteps
