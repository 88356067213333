import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../../../utils"

const MobileInvestorsInfo = () => {
  return (
    <div className="md:hidden bg-light-tangerine border-t border-b border-brownish-grey pt-[150px] mt-[-180px] pb-[50px] mb-[60px]">
      <div className="flex items-center gap-x-5 justify-between pl-10 md:hidden">
        <div>
          <p className="max-w-[169px] mt-0 mb-2">
            76% of investors make profit when copying top traders
            correctly. You’ll see performance data to help guide
            your decision to follow pro traders.
          </p>

          <Button
            type="primary"
            label="Try Copytrade"
            url="https://eaglegm.zulutrade.com/"
            isExternal
            className="mt-[60px] w-full md:hidden"
          />
        </div>
        <StaticImage
          className="max-w-[180px]"
          src="../../../../assets/images/pages/copytrade/half-laptop.webp"
          alt="Investor statistics"
        />
      </div>
      <StaticImage
        className="max-w-[310px] md:hidden"
        src="../../../../assets/images/pages/copytrade/half-chart.webp"
        alt="Investor statistics"
      />
    </div>
  )
}

export default MobileInvestorsInfo