import * as React from "react"
import Layout from "../../components/layout"
import Container from "../../components/utils/container"
import { CopytradePlatformChart, CopytradePlatformIntro } from "../../components/pages/platforms/copytrade"
import CopytradeHowToGetStarted from "../../components/pages/platforms/copytrade/how-to-get-started"
import CopytradeTradersEcosystem from "../../components/pages/platforms/copytrade/traders-ecosystem"
import MobileInvestorsInfo from "../../components/pages/platforms/copytrade/mobile-investors-info"

const CopytradePlatformPage = () => (
  <Layout
    title="Copy trades from experts using Copytrade"
    description='Earn money by copying trades from expert traders using our copy trading platform.'
    footerClassName="!mt-[100px]"
  >
    <div className="relative overflow-hidden">
      <Container size="large">
        <CopytradePlatformIntro />
      </Container>
    </div>
    <MobileInvestorsInfo />
    <CopytradePlatformChart />
    <div className="relative">
      <CopytradeHowToGetStarted />
    </div>
    <div className="relative">
      <div className="relative md:pb-[100px]">
        <CopytradeTradersEcosystem />
      </div>
    </div>
  </Layout>
)

export default CopytradePlatformPage
