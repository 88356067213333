import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Flex from "../../../utils/flex"
import ctl from "@netlify/classnames-template-literals"
import { Button, Container } from "../../../utils"

const CopytradePlatformChart = () => {
  return (
    <div className="md-max:hidden md:pt-[52px] mt-[40px] md:pb-[100px] border-t border-b border-brownish-grey bg-light-tangerine"
    >
      <Container
        size="large"
        className="md:pl-[30px]"
      >
        <Flex
          className="md:pt-0 md-max:hidden"
          firstItem={
            <StaticImage
              src="../../../../assets/images/pages/copytrade/copytrade-chart.webp"
              alt="Zulu trade desktop and mobile"
              className={imageStyle}
            />
          }
          secondItem={
            <>
              <p className="mt-0 max-w-[491px]">
                76% of investors make profit when copying top traders
                correctly. You’ll see performance data to help guide
                your decision to follow pro traders.
              </p>
              <Button
                type="primary"
                label="Try Copytrade"
                url="https://eaglegm.zulutrade.com/"
                isExternal
                className="md:mt-[30px] w-[200px]"
              />
            </>
          }
        />
      </Container>
    </div>
  )
}

const imageStyle = ctl(`max-w-[650px]`)
export default CopytradePlatformChart
